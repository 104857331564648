import { Bleed, HStack } from '@styled-system/jsx';
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Modal,
  notification,
  Select,
} from 'antd';
import { FormItem } from 'react-hook-form-antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DefaultTrackDistributionFormValues,
  TrackDistributionSchemaType,
} from '../../model/trackDistributionSchema';

import { useGetDictionariesQuery } from '@gql_codegen/price-management-config-types';
import { FormItemWithRules } from '@src/shared/components/form-item-with-rules';
import { useTrackDistributionStore } from '../../store/trackDistributionStore';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';

type CluseterSetupModal = {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
};

export const CluseterSetupModal: React.FC<CluseterSetupModal> = ({
  setIsModalOpen,
  isModalOpen,
}: CluseterSetupModal) => {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useFormContext<TrackDistributionSchemaType>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'configs',
  });
  const { data } = useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
  );
  const {
    countries = [],
    experimentNames = [],
    priceClusters = [],
    tracks = [],
  } = data.getDictionaries;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setNewClusterData = useTrackDistributionStore(
    (state) => state.setNewClusterData,
  );

  return (
    <Modal
      destroyOnClose={true}
      width={640}
      title="Track Distribution settings"
      data-qa-selector="trackDistributionModalWrapper"
      open={isModalOpen}
      afterClose={() => {
        reset(DefaultTrackDistributionFormValues);
      }}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        layout="vertical"
        data-qa-selector="trackDistributionFormWrapper"
        noValidate={true}
        onFinish={(event: React.BaseSyntheticEvent) => {
          void handleSubmit(
            (data) => {
              if (isDirty) {
                setNewClusterData(data);
              } else {
                notification.error({
                  message: 'Skipped due to no changes!',
                });
              }
              setIsModalOpen(false);
            },
            (errors) => {
              console.log(
                '[CluseterSetupModal]::handleSubmit::FORM_ERRORS =>',
                errors,
              );
            },
          )(event);
        }}
      >
        <HStack>
          <FormItem
            style={{ width: 200 }}
            control={control}
            name="country"
            label="Country"
            required
            help={
              <div data-qa-selector="countryValidationError">
                {errors.country?.message}
              </div>
            }
          >
            <Select
              options={countries.map((i) => ({ label: i, value: i }))}
              data-qa-selector="countrySelect"
            ></Select>
          </FormItem>
          <FormItem
            style={{ width: 200 }}
            control={control}
            name="priceCluster"
            label="Price Cluster"
            required
            help={
              <div data-qa-selector="priceClusterValidationError">
                {errors.priceCluster?.message}
              </div>
            }
          >
            <Select
              options={priceClusters.map((i) => ({ label: i, value: i }))}
              data-qa-selector="priceClusterSelect"
            ></Select>
          </FormItem>
        </HStack>
        <Divider orientation="left">
          <span>Track Distribution</span>
        </Divider>
        {fields.map((field, idx) => (
          <HStack
            key={`${field.id}_${idx}`}
            alignItems={'top'}
            justify={'start'}
          >
            <FormItem
              style={{ width: 160 }}
              control={control}
              name={`configs.${idx}.track`}
              label="Track"
              required
              help={
                <div data-qa-selector="trackValidationError">
                  {errors.configs?.message}
                </div>
              }
            >
              <Select
                options={tracks.map((i) => ({ label: i, value: i }))}
                data-qa-selector="trackSelect"
              ></Select>
            </FormItem>
            <FormItem
              style={{ width: 250 }}
              control={control}
              name={`configs.${idx}.experimentName`}
              label="Experiment Name"
              required
              help={
                <div data-qa-selector="experimentNameValidationError">
                  {errors.configs?.message}
                </div>
              }
            >
              <Select
                options={experimentNames.map((i) => ({ label: i, value: i }))}
                data-qa-selector="experimentNameSelect"
              ></Select>
            </FormItem>
            <FormItemWithRules
              style={{ width: 160 }}
              controllerProps={{
                control,
                name: `configs.${idx}.distributionRatio`,
                rules: {
                  deps: Array.from(
                    { length: fields.length },
                    (_, idx) => idx,
                  ).map(
                    (fieldIdx) =>
                      `configs.${fieldIdx}.distributionRatio` as const,
                  ),
                },
              }}
              label="Ratio, %"
              required
              help={
                <div data-qa-selector="ratioValidationError">
                  {errors.configs?.message}
                </div>
              }
            >
              <InputNumber
                style={{ width: 160 }}
                min={0}
                max={100}
                data-qa-selector="ratioValueInput"
                addonAfter={
                  <Bleed inline={'11px'}>
                    <Button
                      onClick={() => {
                        remove(idx);
                      }}
                      data-qa-selector="removeTrackButton"
                    >
                      <DeleteOutlined
                        style={{ color: 'red', fontSize: '18px' }}
                      />
                    </Button>
                  </Bleed>
                }
              ></InputNumber>
            </FormItemWithRules>
          </HStack>
        ))}
        <Button
          data-qa-selector="addNewTrackButton"
          onClick={() => {
            append({ track: '', experimentName: '', distributionRatio: 0 });
          }}
        >
          <PlusOutlined /> Add track
        </Button>
        <HStack justify="end">
          <Button onClick={handleCancel} data-qa-selector="cancelButton">
            Cancel
          </Button>{' '}
          <Button
            htmlType="submit"
            type="primary"
            data-qa-selector="submitButton"
          >
            Ok
          </Button>
        </HStack>
      </Form>
    </Modal>
  );
};
